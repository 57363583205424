/* start - для OrdersForm.js */
.orders-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1600px;
    /* max-width: 1500; */
    /* background-color: background.paper; */
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    padding: 15px;
}
  
@media (max-width: 1600px) {
    .orders-modal {
        width: 95vw;
    }
}

@media (max-width: 992px) {
    .orders-modal {
        height: 98vh;
        overflow-x: auto;
    }
}

@media (max-width: 576px) {
    .orders-modal {
        padding-top: 50px;
    }
}
   
/* @media (max-width: 1200px) {
    .orders-modal {
        width: 970px
        width: 95vw;
        padding: calc(20px + (50 - 20) * ((100vw - 320px) / (1200 - 320)));
    }
} */

.orders__linkToOrderProduct {
    text-decoration: underline;
    color: rgb(3, 152, 158);
    cursor: pointer;
}
  
.orders-choose__table {
    width: 100%;
}

.orders-choose__th {
    font-size: 14px;
}

.orders-choose__th-img {
    width: 50px;
}

.orders-choose__th-price {
    width: 50px;
}

.orders-choose__th-count {
    width: 90px;
}
.orders-choose__TC {
    display: flex;
    flex-direction: column;
}

.orders-choose__th-action {
    width: 35px;
}

.orders-choose__mobile, .orders-choose__short-mobile {
    display: none;
}
/* .orders-choose__PC, .orders-choose__short-PC {
    display: block;
} */

@media (max-width: 1400px) {
    .orders-choose__th-img {
        width: 40px;
    }
}

@media (max-width: 1200px) {
    .orders-choose__mobile, .orders-choose__short-mobile {
        display: block;
    }
    .orders-choose__PC, .orders-choose__short-PC {
        display: none;
    }
    .orders-choose__th-img {
        width: 30px;
    }
}

@media (max-width: 576px) {
    .orders-choose__th-img {
        display: none;
    }
}

.orders-choose__img {
    width: 50px;
}

.orders-choose__name {
    max-width: 150px;
    overflow: hidden;
    cursor: pointer;
    font-size: 14px;
}
  
.orders-choose__text {
    font-size: 14px;
}

.orders-choose__count {
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 90px;
    line-height: 17px; 
}

.orders-choose__count-1r {
    width: 90px;
    margin-bottom: 5px;
}
.orders-choose__count-2r {
    width: 90px;
    display: flex;
    justify-content: space-between;
}

.orders-choose__count-input {
    width: 90px;
  font-size: 14px;
}

.orders-choose__count-button {
    width: 40px;
    font-size: 14px;
}

@media (max-width: 1400px) {
    .orders-choose__img {
        width: 40px;
    }
}

@media (max-width: 1200px) {
    .orders-choose__img {
        width: 30px;
    }
}

@media screen and (max-width: 991px) {
    .orders-choose__name {
        font-size: 16px;
    }
    .orders-choose__text {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .orders-choose__td-img {
        display: none;
    }
    .orders-choose__cart {
        padding: 3px !important;
    }
    .orders-choose__name, .orders-choose__text {
        font-size: 14px;
    }
}

@media screen and (max-width: 448px) {  
    .orders-choose__name, .orders-choose__text {
        font-size: 13px;
    }

    .orders-choose__count-1r, .orders-choose__count-2r {
        width: 100%;
    }

    .orders-choose__count-input {
        width: 100%;
    }
    
    .orders-choose__count-button {
        width: 100%;
    }
  }


/* Start - для ViewOrdersMain.js */
.orders-ViewMain__TC {
    display: flex;
    flex-direction: column;
}
/* End - для ViewOrdersMoin.js */


/* Start - для ViewOrdersModal.js */
.orders-ViewModal__mobile {
    display: none;
}
.orders-ViewModal__PC {
    display: block;
}
.orders-ViewModal__TC {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1200px) {
    .orders-ViewModal__mobile {
        display: block;
    }
    .orders-ViewModal__PC {
        display: none !important;
    }
}

/* End - для ViewOrdersModal.js */


/* Start - для ProductInfo.js */
.orders-productInfo__text {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
}

.orders-productInfo__count {
    width: 100%;
    display: flex;
}

.orders-productInfo__count-btn {
    width: 45px;
}

.orders-productInfo__count-input {
    width: 100%;
}

.orders-productInfo__btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1200px) {
    .orders-productInfo__count, .orders-productInfo__btn-container {
        width: 217px;
    }
}

/* End - для ProductInfo.js */


/* Start - для UpdateProductItem.js */
.orders-update__text {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
}
.orders-update__input-price {
    width: 100%;
    font-size: 17px;
    text-align: center;
}

.orders-update__btn-counter {
    height: 28px;
    width: 28px;
    font-size: 17px;
}

.orders-update__input-counter {
    width: calc(100% - 30px);
    font-size: 17px;
}

/* End - для UpdateProductItem.js */


.orders-tableHeader {
    padding-left: 12px;
    font-size: 20px;
    color: rgb(33, 37, 41);
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 32px;
}

@media (max-width: 991px) {
    .orders-tableHeader {
        line-height: 25px;
    }
}
  
/* start orders page QuickProductAddition */
.orders-btn__close {
    width: 106px;
    line-height: 17px !important;
    padding: 0px 2px 5px 2px !important;
    cursor: pointer !important;
    position: absolute;
    top: -40px;
    right: -10px;
    border: 1px solid #000;
    text-align: center;
}
.orders-btn__close:hover {
    background-color: red !important;
    color: #000 !important;
}
.orders-btn__close:active {
    transform: scale(0.9);
}

.orders-quickProductAddition__btn-close {
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    background-color: #6c757d;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    position: absolute;
    top: -65px;
    right: -25px;
    cursor: pointer;
}
.orders-quickProductAddition__btn-close:hover {
    background-color: red;
    color: #000;
    border: 1px solid #000;
}
.orders-quickProductAddition__btn-close:active {
    transform: scale(0.9);
}

@media (max-width: 576px) {
    .orders-btn__close {
        top: -75px;
    }
}

@media (max-width: 414px) {
    .orders-quickProductAddition__btn-close {
        top: -95px;
        right: -25px;
    }
}

@media (max-width: 350px) {
    .orders-btn__close {
        top: -105px;
    }
}
  
/* end orders page QuickProductAddition */
  
/* start orders page AddProductToOrder */
.orders-chooseProduct__container {
    margin-top: 5px;
    padding: 0px 5px 5px;
    border: 5px solid grey;
}
  
@media (max-width: 991px) {
    .orders-chooseProduct__container {
        border: none;
        border-top: 7px solid grey;
        margin-top: 30px;
    }
}
  
.orders-viewProductOrders__container {
    margin-top: 5px;
    padding: 0px 5px 5px;
    border: 5px solid grey;
}
  
@media (max-width: 991px) {
    .orders-viewProductOrders__container {
        border: none;
        border-top: 7px solid grey;
        margin-top: 15px;
    }
}
  
.orders-productInfo__container, .orders-updateProductItem__container {
    margin-top: 5px;
    border: 5px solid grey;
}
  
.orders-productInfo__btn-close, .orders-updateProductItems__btn-close {
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    background-color: #6c757d;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.orders-productInfo__btn-close:hover, .orders-updateProductItems__btn-close:hover {
    background-color: red;
    color: #000;
    border: 1px solid #000;
}
.orders-productInfo__btn-close:active, .orders-updateProductItems__btn-close:active {
    transform: scale(0.9);
}

@media (max-width: 991px) {
    .orders-productInfo__container, .orders-updateProductItem__container {
        border: none;
        border-top: 7px solid grey;
        margin-top: 15px;
    }
}

/* end orders page AddProductToOrder */